import "./product.css";
// import {useRef} from "react"



const Product = ({img,link,name}) => {

//   const handleMouse=()=>{
//   reference.current.style.display="block"

// }
  // const reference=useRef(null)
  return (
    <div className="flexbox">
    <div className="p" >
      <div className="p-browser" style={{display:"flex",}}>
         <div className="custom">
            <div className="p-circle"></div>
        <div className="p-circle"></div>
        <div className="p-circle"></div>
         </div>
       
  <div style={{width:"fit-content",fontSize:"0.75rem",fontWeight:"bold"}}>{name}</div>
      </div>
      <a href={link} target="_blank" rel="noreferrer">
        <img src={img} alt="" className="p-img" />
      </a>
     
    </div>
    <div className="show">
     {/* <div className=" button" style={{display:"none"}}>view code</div> */}
      <div className=" button" style={{display:"none"}}>
       
        <a href={link}  style={{text:"bold",color:"white",textTransform:"none"}}> view project</a>
        </div>
      </div>
 </div>
  );
};

export default Product;
