import "./productList.css";
import Product from "../product/Product";
import { products } from "../../data";
import {useState} from "react"
import { useEffect } from "react";

const ProductList = () => {
  const [category,setCategory]=useState("all")
  const [posts,setPosts]=useState(products)

  const certainFunc=()=>{
     const FilteredProducts=products.filter((item)=>{
   return item.category===category
  })
  setPosts(FilteredProducts)
  if(category==="all"){
    setPosts(products)
  }
  }
  
useEffect(()=>{
certainFunc()
},[category])
  
  return (
    <div className="pl">
      <div className="pl-texts">
        <h1 className="pl-title">My projects</h1>
        <p className="pl-desc">
          These are some of my projects
        </p>
      </div>
      <div className="btn-container">
      <div className="btn" onClick={()=>setCategory('all')}>All</div>
      <div className="btn" onClick={()=>setCategory('MERN-STACK')}>MERN-STACK</div>
      <div className="btn" onClick={()=>setCategory('React')}>React</div>
      <div className="btn" onClick={()=>setCategory('HTMLCSSJS')} style={{width:'100%',maxWidth:"90px"}}>HTML/CSS/JS</div>
      </div>
      <div className="pl-list">
        
        {posts.map((item) => (
          
           <Product key={item.id} img={item.img} link={item.link} name={item.name} />
          
         
        ))}
        </div>
 
    </div>
  );
};

export default ProductList;
