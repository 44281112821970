import "./about.css";
import AnotherPhoto from "../../img/anotherphoto.jpg";
import Me from "../../img/me.jpg";
const About = () => {
  return (
    <div className="a">
      <div className="a-left">
        <div className="a-card bg"></div>
        <div className="a-card">
          <img
            // src="https://images.pexels.com/photos/3585047/pexels-photo-3585047.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
            src={AnotherPhoto}
            alt=""
            className="a-img"
          />
        </div>
      </div>
      <div className="a-right">
        <h1 className="a-title">About Me</h1>
        <p className="a-sub">
         I am a  recent high school graduate from St Xaviers College,Maitighar.
        </p>
        <p className="a-desc">
         During my gap-year, I've taught myself full stack web development. I am proficient in HTML,CSS,JS,React,MongoDB,Node and TailwindCSS. Besides,Web development, I am a passionate educator.I've taught bridge course at Saral Shikshya.I share keen interest in fingerstyle guitar as well .Hardworking,Team-player and Life-Long Learner, these are the 3 words that define me. I look forward working with you😊
        </p>
        {/* <div className="a-award">
          <img src={Award} alt="" className="a-award-img" />
          <div className="a-award-texts">
            <h4 className="a-award-title">International Design Awards 2021</h4>
            <p className="a-award-desc">
              Nemo enim ipsam voluptatem quia voluptas sit aspernatur autodit
              and fugit.
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default About;
