export const products = [
  {
    id: 1,
    img: "https://res.cloudinary.com/ddgpavgah/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1651224050/portfolio/screencapture-magical-cocktail-291d05-netlify-app-2022-04-28-19_38_24_adri6d.png",
    name:"cocktail",
    category:"React",
    link: "https://project-cocktails-app.netlify.app/",
  },
  {
    id: 2,
    img: "https://res.cloudinary.com/ddgpavgah/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1651224048/portfolio/screencapture-project-menu-app-netlify-app-2022-04-28-19_55_05_c84bqu.png",
    name:"menu",
    category:"React",
    link: "https://project-menu-app.netlify.app/",
  },
  {
    id: 3,
    img: "https://res.cloudinary.com/ddgpavgah/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1651224047/portfolio/screencapture-react-blog-app-mernstack-netlify-app-2022-04-28-19_21_21_ji2yps.png",
    name:"ReactBlog",
    category:"MERN-STACK",
    link: "https://react-blog-app-mernstack.netlify.app/",
  },
  {
    id: 4,
    img: "https://res.cloudinary.com/ddgpavgah/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1651224047/portfolio/screencapture-127-0-0-1-5500-index-html-2022-04-28-20_13_34_vet39h.png",
    name:"lamborghini",
    category:"HTMLCSSJS",
    link: "https://lamborghini-project.netlify.app/",
  },
  {
    id: 5,
    img: "https://res.cloudinary.com/ddgpavgah/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1651224047/portfolio/screencapture-project-ecommerce-cart-netlify-app-2022-04-28-19_53_37_apciay.png",
    name:"cart",
    category:"React",
    link: "https://project-ecommerce-cart.netlify.app/",
  },
  {
    id: 6,
    img: "https://res.cloudinary.com/ddgpavgah/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1651224046/portfolio/screencapture-project-recipes-netlify-app-index-html-2022-04-28-19_34_39_wybugn.png",
    name:"recipes",
    category:"HTMLCSSJS",
    link: "https://project-recipes.netlify.app/",
  },
   {
    id: 7,
    img: "https://res.cloudinary.com/ddgpavgah/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1651224044/portfolio/screencapture-analogclock-project-netlify-app-2022-04-28-20_19_25_zqv8yk.png",
    name:"analog-clock",
    category:"HTMLCSSJS",
    link: "https://analogclock-project.netlify.app/",
  },
   {
    id: 8,
    img: "https://res.cloudinary.com/ddgpavgah/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1651224044/portfolio/screencapture-tailwind-landerpage-clone-netlify-app-2022-04-28-20_05_43_ydl4k9.png",
    name:"tailwind-lander-pg",
    category:"tailwind",
    link: "https://tailwind-landerpage-clone.netlify.app/",
  },
   {
    id: 9,
    img: "https://res.cloudinary.com/ddgpavgah/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1651224044/portfolio/screencapture-tailwind-fb-loginpage-clone-netlify-app-2022-04-28-19_58_26_msl1cs.png",
    name:"fb-loginpage-clone",
    category:"tailwind",
    link: "https://tailwind-fb-loginpage-clone.netlify.app/",
  }
];
